html {
  -webkit-overflow-scrolling: touch !important;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  position: relative;

  height: 100%;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  overflow: hidden;
}

/* @media (device-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  html {
    height: calc(100% - 44px);
    padding-top: 44px;
  }
} */

body {
  margin: 0;
  padding: 0;
  overflow: hidden;

  width: 100%;
  height: 100%;
  height: calc(100% + constant(safe-area-inset-bottom));
  height: calc(100% + env(safe-area-inset-bottom));
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Roboto';
}

/*
Using :focus-visible polyfill to hide focus ring for mouse focus but keeping it for keyboard focus.
See: https://github.com/WICG/focus-visible
 */

[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none;
}

[data-js-focus-visible] [data-focus-visible-added] {
  outline: auto;
}
